import React, { useContext, useEffect } from 'react'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import { useTranslation, Trans } from 'react-i18next'
import Heading from 'src/components/typography/Heading'

export default function TermsAndConditions(props) {
    const { t } = useTranslation()
    const { setTheme } = useContext(ThemeContext)

    useEffect(() => setTheme('inverse'), []);

    return (
        <MarketingLayout {...props} title={t('general.nav.terms&Conditions')}>
            <div className="py-16 mx-auto container">
                <Heading type="sub-title">{t('termsAndConditions.title')}</Heading>
                <ol className="list-decimal pl-4">
                    {t('termsAndConditions.paragraphs', { returnObject: true }).map((paragraph, index) => (
                        <li className="my-10 pl-2" key={index}>
                            <Trans
                                i18nKey={paragraph.text}
                                components={{ italic: <i />, bold: <strong /> }}
                            />
                            {paragraph.children && (
                                <ol className="list-roman pl-6">
                                    {paragraph.children.map((item, i) => (
                                        <li className="my-6 pl-2" key={i}>
                                            <Trans
                                                i18nKey={item.text}
                                                components={{ italic: <i />, bold: <strong /> }}
                                            />
                                        </li>
                                    ))}
                                </ol>
                            )}
                        </li>
                    ))}
                </ol>
            </div>
        </MarketingLayout>
    )
}
